<template>
  <v-container>
    <h1 style="margin-bottom: 40px">Videokonferenzräume des digitalen Gemeindehauses</h1>
      
    <v-container style="margin-bottom: 40px">
      <v-row>
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12">
              <v-img v-if="rooms && rooms.left_image.id" :src="$ASSET_URL + rooms.left_image.id + '?width=1280&quality=85'" max-height="400">
                <v-row justify="center" align="center" class="fill-height">
                  <v-col cols="12" class="text-center">
                    <v-btn color="#283583" class="white--text" :href="room1Url" target="_blank">
                      Großen Raum betreten - für 20 Personen
                    </v-btn>
                  </v-col>
                </v-row>
              </v-img>
            </v-col>
            <!--<v-col cols="12">
              <h2>Grosser Raum: Aktuelle Reservierungen</h2>
            </v-col>
            <v-col v-if="rooms" cols="12" class="pa-2" v-html="rooms.room_1" />
            -->
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12">
              <v-img v-if="rooms && rooms.right_image.id" :src="$ASSET_URL + rooms.right_image.id + '?width=1280&quality=85'" max-height="400">
                <v-row justify="center" align="center" class="fill-height">
                  <v-col cols="12" class="text-center">
                    <v-btn color="#283583"  class="white--text" :href="room2Url" target="_blank">
                      Kleinen Raum betreten- für 10 Personen
                    </v-btn>
                  </v-col>
                </v-row>
              </v-img>
            </v-col>
            <!--<v-col cols="12">
              <h2>Kleiner Raum: Aktuelle Reservierungen</h2>
            </v-col>
            <v-col v-if="rooms" cols="12" class="pa-2" v-html="rooms.room_2" />
            -->
          </v-row>
        </v-col>
        
      </v-row>
    </v-container>

    <v-container>
      <v-row v-if="rooms" style="margin-top: 20px;">
        <v-col cols="12" md="6" class="pa-2" v-html="rooms.box_1" />
        <v-col cols="12" md="6" class="pa-2" v-html="rooms.box_2" />
        <v-col cols="12" md="6" class="pa-2" v-html="rooms.box_3" />
        <v-col cols="12" md="6" class="pa-2" v-html="rooms.box_4" />
        <v-col cols="12" md="6" class="pa-2" v-html="rooms.box_5" />
        <v-col cols="12" md="6" class="pa-2" v-html="rooms.box_6" />
      </v-row>
    </v-container>

  </v-container>
</template>

<script>

export default {
  name: 'Rooms',
  components: {
  },
  data: () => ({
    rooms: null,
    room1Url: process.env.VUE_APP_ROOM1_URL,
    room2Url: process.env.VUE_APP_ROOM2_URL,
  }),
  async created() {
    let temp = await this.$client.items("rooms").readMany({ fields: ['*.*']})
    this.rooms =  temp.data
  }

}
</script>